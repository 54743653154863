.delete-category {
  color: red !important; 
  cursor: pointer;
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
}

.delete-category:hover {
  text-decoration: underline !important;
}
.delete-category:focus {
  outline: none;
}